import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMenu,{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',[_c(VIcon,_vm._g({directives:[{name:"show",rawName:"v-show",value:(_vm.getIcon()),expression:"getIcon()"}],attrs:{"color":_vm.iconColor}},on),[_vm._v(_vm._s(_vm.getIcon()))]),_c(VChip,_vm._g({directives:[{name:"show",rawName:"v-show",value:(_vm.value),expression:"value"}],attrs:{"small":"","color":_vm.color,"dark":_vm.dark}},on),[_vm._v(" "+_vm._s(_vm.text || _vm.value)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.tooltipLabel))])])],1)]}}])},[_c('base-filter',_vm._b({attrs:{"value":_vm.value,"clearValue":_vm.clearValue},on:{"update:text":_vm.updateText,"input":_vm.emit}},'base-filter',_vm.$attrs,false))],1)}
var staticRenderFns = []

export { render, staticRenderFns }