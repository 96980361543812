<template>
  <v-menu>
    <template v-slot:activator="{ on }">
      <span v-on="on">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <span>
              <v-icon v-show="getIcon()" v-on="on" :color="iconColor">{{ getIcon() }}</v-icon>
              <v-chip small v-show="value" v-on="on" :color="color" :dark="dark">
                {{ text || value }}
              </v-chip>
            </span>
          </template>
          <span>{{ tooltipLabel }}</span>
        </v-tooltip>
      </span>
    </template>
    <base-filter
      @update:text="updateText"
      v-bind="$attrs"
      :value="value"
      :clearValue="clearValue"
      @input="emit"
    ></base-filter>
  </v-menu>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: ''
    },
    value: {
      type: String
    },
    clearValue: {
      type: String,
      default: ''
    },
    iconColor: {
      type: String,
      default: ''
    },
    tooltipLabel: {
      type: String,
      default: 'Filter Rows'
    }
  },
  data() {
    return {
      text: '',
      color: 'primary',
      dark: false
    };
  },
  created() {
    this.checkStyles();
  },
  methods: {
    checkStyles() {
      if (!this.name) {
        return;
      }
      let style = localStorage.getItem(`table-filter-${this.name}`);
      if (!style) {
        return;
      }
      try {
        style = JSON.parse(style);
        this.text = style.text;
        this.color = style.color;
        this.dark = style.dark;
      } catch {}
    },
    updateText(value, color, dark) {
      this.text = value;
      this.color = color;
      this.dark = dark;

      const data = { text: this.text, color: this.color, dark: this.dark };
      if (this.name) {
        localStorage.setItem(`table-filter-${this.name}`, JSON.stringify(data));
      }
    },
    getIcon() {
      if (this.value == this.clearValue) {
        return 'mdi-filter-off';
      } else {
        return '';
      }
    },
    emit(val) {
      this.$emit('input', val);
    }
  }
};
</script>

<style></style>
